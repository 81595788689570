import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

/**
 * Servicio que contiene la lógica para mostrar notificaciones
 */
@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	duration = 5000
	autoClose = true
	dismissible = true
	constructor(private readonly toastr: ToastrService) {}

	success(message: string): void {
		this.toastr.success(message, '', {
			toastClass: 'ngx-toastr custom-toast toast-success',
			easing: 'ease-in',
			easeTime: 300,
		})
	}

	info(message: string): void {
		this.toastr.info(message, '', {
			toastClass: 'ngx-toastr custom-toast toast-info',
			easing: 'ease-in',
			easeTime: 300,
		})
	}

	error(message: string): void {
		this.toastr.error(message, '', {
			toastClass: 'ngx-toastr custom-toast toast-error',
			easing: 'ease-in',
			easeTime: 300,
		})
	}
}
